export type UserState = 'ACTIVE' | 'INACTIVE';

export const USER_STATE: { [key in UserState]: UserState } = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const USER_STATES: Readonly<
  {
    label: string;
    value: UserState;
  }[]
> = [
  { label: 'Activo', value: USER_STATE.ACTIVE },
  { label: 'Inactivo', value: USER_STATE.INACTIVE },
];

export type CustomerState = 'ACTIVE' | 'INACTIVE';

export const CUSTOMER_STATE: { [key in CustomerState]: CustomerState } = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const CUSTOMER_STATES: Readonly<
  {
    label: string;
    value: CustomerState;
  }[]
> = [
  { label: 'Activo', value: CUSTOMER_STATE.ACTIVE },
  { label: 'Inactivo', value: CUSTOMER_STATE.INACTIVE },
];

export type RequestState =
  | 'REGISTERED'
  | 'PENDING'
  | 'REJECTED'
  | 'APPROVED'
  | 'MANAGED'
  | 'ASSIGNED'
  | 'COMPLETED'
  | 'CLOSED'
  | 'CANCELED'
  | 'OBSERVED';

export const REQUEST_STATE: Readonly<{ [key in RequestState]: RequestState }> =
  {
    REGISTERED: 'REGISTERED',
    PENDING: 'PENDING',
    REJECTED: 'REJECTED',
    APPROVED: 'APPROVED',
    MANAGED: 'MANAGED',
    ASSIGNED: 'ASSIGNED',
    COMPLETED: 'COMPLETED',
    CLOSED: 'CLOSED',
    CANCELED: 'CANCELED',
    OBSERVED: 'OBSERVED',
  };

export const REQUEST_STATES_OPTIONS: Readonly<
  {
    label: string;
    value: RequestState;
  }[]
> = [
  { label: 'Registrada', value: REQUEST_STATE.REGISTERED },
  { label: 'Pendiente', value: REQUEST_STATE.PENDING },
  { label: 'Rechazada', value: REQUEST_STATE.REJECTED },
  { label: 'Aprobada', value: REQUEST_STATE.APPROVED },
  { label: 'Gestionada', value: REQUEST_STATE.MANAGED },
  { label: 'Asignada', value: REQUEST_STATE.ASSIGNED },
  { label: 'Completada', value: REQUEST_STATE.COMPLETED },
  { label: 'Cerrada', value: REQUEST_STATE.CLOSED },
];

export const REQUEST_STATES: Readonly<string[]> = [
  REQUEST_STATE.REGISTERED,
  REQUEST_STATE.PENDING,
  REQUEST_STATE.REJECTED,
  REQUEST_STATE.APPROVED,
  REQUEST_STATE.MANAGED,
  REQUEST_STATE.ASSIGNED,
  REQUEST_STATE.COMPLETED,
  REQUEST_STATE.CLOSED,
  REQUEST_STATE.CANCELED,
];

export const REQUEST_STATES_NAME_MAP: Readonly<{
  [key in string]: string;
}> = {
  REGISTERED: 'Registrada',
  PENDING: 'Pendiente',
  REJECTED: 'Rechazada',
  APPROVED: 'Aprobada',
  MANAGED: 'Gestionada',
  ASSIGNED: 'Asignada',
  COMPLETED: 'Completada',
  CLOSED: 'Cerrada',
  CANCELED: 'Cancelada',
};

export type ProductState =
  | 'REGISTERED'
  | 'ASSIGNED'
  | 'FINISHED'
  | 'APPROVED'
  | 'OBSERVED'
  | 'CANCELED'
  | 'COMPLETED';

export const PRODUCT_STATE: Readonly<{ [key in ProductState]: ProductState }> =
  {
    REGISTERED: 'REGISTERED',
    ASSIGNED: 'ASSIGNED',
    FINISHED: 'FINISHED',
    APPROVED: 'APPROVED',
    OBSERVED: 'OBSERVED',
    CANCELED: 'CANCELED',
    COMPLETED: 'COMPLETED',
  };

export const PRODUCT_STATES_OPTIONS: Readonly<
  {
    label: string;
    value: ProductState;
  }[]
> = [
  { label: 'Registrado', value: PRODUCT_STATE.REGISTERED },
  { label: 'Asignado', value: PRODUCT_STATE.ASSIGNED },
  { label: 'Finalizado', value: PRODUCT_STATE.FINISHED },
  { label: 'Aprobado', value: PRODUCT_STATE.APPROVED },
  { label: 'Observado', value: PRODUCT_STATE.OBSERVED },
  { label: 'Anulado', value: PRODUCT_STATE.CANCELED },
  { label: 'Completado', value: PRODUCT_STATE.COMPLETED },
];

export const PRODUCT_STATE_NAME_MAP = {
  REGISTERED: 'Registrado',
  ASSIGNED: 'Asignado',
  FINISHED: 'Finalizado',
  APPROVED: 'Aprobado',
  OBSERVED: 'Observado',
  CANCELED: 'Anulado',
  COMPLETED: 'Completado',
};

export type CollaboratorState =
  | 'REGISTERED'
  | 'ASSIGNED'
  | 'FINISHED'
  | 'APPROVED'
  | 'OBSERVED'
  | 'CANCELED'
  | 'COMPLETED';

export const COLLABORATOR_STATE: Readonly<{
  [key in CollaboratorState]: CollaboratorState;
}> = {
  REGISTERED: 'REGISTERED',
  ASSIGNED: 'ASSIGNED',
  FINISHED: 'FINISHED',
  APPROVED: 'APPROVED',
  OBSERVED: 'OBSERVED',
  CANCELED: 'CANCELED',
  COMPLETED: 'COMPLETED',
};

export const COLLABORATOR_STATES: Readonly<
  {
    label: string;
    value: CollaboratorState;
  }[]
> = [
  { label: 'Registrado', value: COLLABORATOR_STATE.REGISTERED },
  { label: 'Asignado', value: COLLABORATOR_STATE.ASSIGNED },
  { label: 'Finalizado', value: COLLABORATOR_STATE.FINISHED },
  { label: 'Aprobado', value: COLLABORATOR_STATE.APPROVED },
  { label: 'Observado', value: COLLABORATOR_STATE.OBSERVED },
  { label: 'Anulado', value: COLLABORATOR_STATE.CANCELED },
];

export const PENDING_STATES: Readonly<
  {
    label: string;
    value: RequestState;
  }[]
> = [
  { label: 'Pendiente', value: REQUEST_STATE.PENDING },
  { label: 'Aprobada', value: REQUEST_STATE.APPROVED },
  { label: 'Gestionada', value: REQUEST_STATE.MANAGED },
  { label: 'Completada', value: REQUEST_STATE.COMPLETED },
  { label: 'Asignada', value: REQUEST_STATE.ASSIGNED },
];

export type RequestCustomerType = 'AGENCY' | 'STRAIGHT' | 'STATE';

export const REQUEST_CUSTOMER_TYPE: Readonly<{
  [key in RequestCustomerType]: RequestCustomerType;
}> = {
  AGENCY: 'AGENCY',
  STRAIGHT: 'STRAIGHT',
  STATE: 'STATE',
};

export const REQUEST_CUSTOMER_TYPES: Readonly<
  {
    label: string;
    value: RequestCustomerType;
  }[]
> = [
  { label: 'Agencia', value: REQUEST_CUSTOMER_TYPE.AGENCY },
  { label: 'Directo', value: REQUEST_CUSTOMER_TYPE.STRAIGHT },
  { label: 'Estado', value: REQUEST_CUSTOMER_TYPE.STATE },
];

export const REQUEST_CUSTOMER_TYPE_MAP: Readonly<{
  [key in RequestCustomerType]: string;
}> = {
  AGENCY: 'Agencia',
  STRAIGHT: 'Directo',
  STATE: 'Estado',
};

export type RequestPriority = 'HIGH' | 'HALF' | 'LOW';

export const REQUEST_PRIORITY: Readonly<{
  [key in RequestPriority]: RequestPriority;
}> = {
  HIGH: 'HIGH',
  HALF: 'HALF',
  LOW: 'LOW',
};

export const REQUEST_PRIORITIES: Readonly<
  {
    label: string;
    value: RequestPriority;
  }[]
> = [
  { label: 'Alta', value: REQUEST_PRIORITY.HIGH },
  { label: 'Media', value: REQUEST_PRIORITY.HALF },
  { label: 'Baja', value: REQUEST_PRIORITY.LOW },
];

export const REQUEST_PRIORITY_MAP: Readonly<{
  [key in RequestPriority]: string;
}> = {
  HIGH: 'Alta',
  HALF: 'Media',
  LOW: 'Baja',
};

export type AuditTypeEvent =
  | 'CREATE'
  | 'UPDATE'
  | 'DELETE'
  | 'APPROVE'
  | 'REJECT';

export const AUDIT_TYPE_EVENT: Readonly<{
  [key in AuditTypeEvent]: AuditTypeEvent;
}> = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
};

export const AUDIT_TYPE_EVENTS = [
  { label: 'Crear', value: AUDIT_TYPE_EVENT.CREATE },
  { label: 'Actualizar', value: AUDIT_TYPE_EVENT.UPDATE },
  { label: 'Eliminar', value: AUDIT_TYPE_EVENT.DELETE },
  { label: 'Aprobar', value: AUDIT_TYPE_EVENT.APPROVE },
  { label: 'Rechazar', value: AUDIT_TYPE_EVENT.REJECT },
];

export const AUDIT_TYPE_EVENTS_MAP: Readonly<{
  [key in AuditTypeEvent]: string;
}> = {
  CREATE: 'Crear',
  UPDATE: 'Actualizar',
  DELETE: 'Eliminar',
  APPROVE: 'Aprobar',
  REJECT: 'Rechazar',
};

export type FileState = 'SUCCESS' | 'FAILED' | 'PENDING';

type FileStateValue = {
  [key in FileState]: string;
};

export const FILE_STATES: FileStateValue = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  PENDING: 'PENDING',
};

export type FileType = 'REQUEST' | 'PRODUCT' | 'REVISION';

type FileTypeValue = {
  [key in FileType]: string;
};

export const FILE_TYPES: FileTypeValue = {
  REQUEST: 'REQUEST',
  PRODUCT: 'PRODUCT',
  REVISION: 'REVISION',
};
